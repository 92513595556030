<template>
    <div class="farmtemplate">
        <myhead :param="param" />
        <section>
            <div class="bannerfarm">
                <div style="height:280px; "></div>
                <div style="font-size: 44px;font-weight: bold;color: #ffffff;"></div>

            </div>
        </section>
        <section class="body1200" style="margin-top: 90px;">
            <div class="txt18">智慧路灯应用方案</div>
            <div class="txt60" style="margin-top: 30px">智慧路灯</div>
            <div class="txt18" style="margin-top: 60px;width:860px;">
                光伏农业一体化并网发电项目，将太阳能发电、现代农业种植和养殖、高效 设施农业相结合， 一方面太阳能光伏系统可运用农地直接低成本发电。光伏农业符合生物链关系和生物最佳生产原料能量系统要求、遵循农产品生产规律并创新物质和能量转换技术，以达到智能补光、补水及调温的目的，而其产出的农产品将比现有方式生产的产品更安全、更营养、更多产。
            </div>
        </section>
        <section class="body1200" style="margin-top: 160px;">
            <div  class="txt42">
                方案概述
            </div>

            <div class="txt18" style="margin-top: 30px;width:860px;">
                光伏农业的光伏技术主要有：光伏提水技术(光伏扬水系统)、光伏水泵、滴灌、喷灌、微灌等产品。光伏农业的意义相较于传统农业而言，光伏农业是一场实现农场变工厂、田间变车间的生产方式变革。
            </div>
            <div class="htable"   style="margin-top: 60px; justify-content: space-between;text-align: center;font-size: 24px;">
                <div class="hcell"><div><img :src="farm_gs1"> </div><div>新型太阳能生态农业大棚</div></div>
                <div class="hcell"><div><img :src="farm_gs2"> </div><div>新型农村太阳能发电站</div></div>
                <div class="hcell"><div><img :src="farm_gs3"> </div><div>智能光伏灌溉系统</div></div>
            </div>
            <div class="htable"   style="margin-top: 60px; justify-content: space-between;text-align: center;font-size: 24px;">
                <div class="hcell"><div><img :src="farm_gs4"> </div><div>太阳能污水净化系统</div></div>
                <div class="hcell"><div><img :src="farm_gs5"> </div><div>农用太阳能路灯</div></div>
                <div class="hcell"><div style="width: 374px"> </div></div>
            </div>
        </section>
        <section class="body1200" style="margin-top: 160px;">
            <div  class="txt42">
                理念与架构
            </div>
            <div class="farm_jg" style="margin-top: 60px;">
            </div>
        </section>
        <section class="body1200" style="margin-top: 160px;">
            <div  class="txt42">
                方案展示
            </div>

            <div class="txt18" style="margin-top: 30px;width:860px;">
                智能光伏灌溉系统是面向农业推出的一套光伏系统，是智慧农业的重要组成部分，基于高效简单，全数字化的设计理念，可用于大棚农业，农业灌溉，荒漠自理，草原牧业，花卉种植邓各种场景。
            </div>
            <div class="htable" style="margin-top: 30px;justify-content: center;width: 860px;border-bottom: 1px solid #e7e7e7;">

                <div class="hcell">
                    <span v-if="farmgh==1" class="txtH16 btLine">水肥一体化智能灌溉系统</span>
                    <span v-else class="txtH16 ghblockclick" @click="farmgh=1">水肥一体化智能灌溉系统</span>
                </div>
                <div class="hcell " style="margin-left: 60px">
                    <span v-if="farmgh==2" class="txtH16 btLine">环境监控系统</span>
                    <span v-else class="txtH16 ghblockclick" @click="farmgh=2">环境监控系统</span>
                </div>
                <div class="hcell " style="margin-left: 60px">
                    <span v-if="farmgh==3" class="txtH16 btLine">光照控制系统</span>
                    <span v-else class="txtH16 ghblockclick" @click="farmgh=3">光照控制系统</span>
                </div>
                <div class="hcell " style="margin-left: 60px">
                    <span v-if="farmgh==4" class="txtH16 btLine">自动杀虫系统系统</span>
                    <span v-else class="txtH16 ghblockclick" @click="farmgh=4">自动杀虫系统系统</span>
                </div>

            </div>
        </section>
        <section class="body1200 farm_ghblock" v-if="farmgh==1">
            <div  class="txt42">
                水肥一体化智能灌溉系统
            </div>
            <div class="txt18" style="margin-top: 60px;width:860px;">
                水肥一体化智能灌溉系统能帮助生产者实现水、肥的自动综合管理。系统由系统云平台、水分采集终端、视频监控、施肥机、过滤系统、阀门控制器、电磁阀、现场管道等组成。
            </div>
            <div class="htable"   style="margin-top: 60px; justify-content: space-between;">
                <div class="hcell"><div class="farm_gh farm_gh11"> </div></div>
                <div class="hcell"><div class="farm_gh farm_gh12"> </div></div>
                <div class="hcell"><div class="farm_gh farm_gh13"> </div></div>
            </div>
            <div class="htable"   style="margin-top: 60px; justify-content: space-between;width: 906px;">
                <div class="hcell" style="width: 452px"><div class="farn_tdd">
                    <img :src="farm_gg4">
                    <div class="farn_tddh">自动控制</div>
                    <div class="farn_tddt">根据用户设定的配方，灌溉过程参数自动控制灌溉量、肥料吸收、肥料浓度、酸度、碱度等重要参数实现灌溉。</div>
                </div></div>
                <div class="hcell" style="width: 452px"><div class="farn_tdd">
                    <img :src="farm_gg5">
                    <div class="farn_tddh">计划灌溉</div>
                    <div class="farn_tddt">根据所监测到的土壤含水量和作物品种的肥料需求，整个系统可以建立周期供水和肥料计划进行轮作灌溉。</div>
                </div></div>
            </div>
        </section>
        <section class="body1200 farm_ghblock" v-if="farmgh==2">
            <div  class="txt42">
                环境监控系统
            </div>
            <div class="txt18" style="margin-top: 60px;width:860px;">
                辉腾智慧农业通过太阳能供电给传感器和相关控制设备，与无线通信网络相结合，全方位进行环境监测与控制，实现农业的智能化管理。
            </div>
            <div class="htable"   style="margin-top: 60px; justify-content: space-between;">
                <div class="hcell"><div class="farm_gh farm_gh21"> </div></div>
                <div class="hcell"><div class="farm_gh farm_gh22"> </div></div>
                <div class="hcell"><div class="farm_gh farm_gh23"> </div></div>
            </div>
            <div class="htable"   style="margin-top: 60px; justify-content: space-between;">
                <div class="hcell"  ><div class="farn_tdd">
                    <img :src="farm_hj4">
                    <div class="farn_tddh">智能报警</div>
                    <div class="farn_tddt">根据用户设定的配方，灌溉过程参数自动控制灌溉量、肥料吸收、肥料浓度、酸度、碱度等重要参数实现灌溉。</div>
                </div></div>
                <div class="hcell"  ><div class="farn_tdd">
                    <img :src="farm_hj5">
                    <div class="farn_tddh">数据查询与分析</div>
                    <div class="farn_tddt">实时数据查询，历史数据分析。</div>
                </div></div>
                <div class="hcell"><div class="farn_tdd">
                    <img :src="farm_hj6">
                    <div class="farn_tddh">视频采集</div>
                    <div class="farn_tddt">视频采集、存储及播放</div>
                </div></div>
            </div>
            <div class="htable"   style="margin-top: 60px; justify-content: space-between;">
                <div class="hcell"  style="width: 400px;" ><div class="farn_tdd">
                    <img :src="farm_hj7">
                    <div class="farn_tddh">远程控制</div>
                    <div class="farn_tddt">远程、自动化控制设备。</div>
                </div></div>

            </div>
        </section>
        <section class="body1200 farm_ghblock" v-if="farmgh==3">
            <div  class="txt42">
                光照控制系统
            </div>
            <div class="txt18" style="margin-top: 60px;width:860px;">
                根据大棚内光线强弱及植物生长周期的光照需求来自动调节光照，提高了大棚控制系统的智能化程度，从而提高大棚生产效率。
            </div>
            <div class="htable"   style="margin-top: 60px; justify-content: space-between;">
                <div class="hcell"><div class="farm_gh farm_gh31"> </div></div>
                <div class="hcell"><div class="farm_gh farm_gh32"> </div></div>
                <div class="hcell"><div class="farm_gh farm_gh33"> </div></div>
            </div>
            <div class="htable"   style="margin-top: 60px; justify-content: space-between;">
                <div class="hcell"  ><div class="farn_tdd">
                    <img :src="farm_gk4">
                    <div class="farn_tddh">光照度检测</div>
                </div></div>
                <div class="hcell"  ><div class="farn_tdd">
                    <img :src="farm_gk5">
                    <div class="farn_tddh">湿帘降温</div>
                </div></div>
                <div class="hcell"><div class="farn_tdd">
                    <img :src="farm_gk6">
                    <div class="farn_tddh">自动补光、遮阳</div>
                </div></div>
            </div>
        </section>
        <section class="body1200 farm_ghblock" v-if="farmgh==4">
            <div  class="txt42">
                自动杀虫系统
            </div>
            <div class="txt18" style="margin-top: 60px;width:860px;">
                自动监测农作物病虫害状况，同时分析农作物生长周期及健康状态，综合环境监测状况，通过云中心分析提出绿色健康的病虫治理方案，运用制动化、智能化杀虫系统进行治疗。
            </div>
            <div class="htable"   style="margin-top: 60px; justify-content: space-between;">
                <div class="hcell"><div class="farm_gh farm_gh41"> </div></div>
                <div class="hcell"><div class="farm_gh farm_gh42"> </div></div>
                <div class="hcell"><div class="farm_gh farm_gh43"> </div></div>
            </div>
            <div class="htable"   style="margin-top: 60px; justify-content: space-between;">
                <div class="hcell"  ><div class="farn_tdd">
                    <img :src="farm_sc4">
                    <div class="farn_tddh">模式选择</div>
                    <div class="farn_tddt">自动选择农药喷洒模式。</div>
                </div></div>
                <div class="hcell"  ><div class="farn_tdd">
                    <img :src="farm_sc5">
                    <div class="farn_tddh">适时杀虫</div>
                    <div class="farn_tddt">根据植物生长情况适时杀虫。</div>
                </div></div>
                <div class="hcell"><div class="farn_tdd">
                </div></div>
            </div>
        </section>

        <section class="body1200" style="margin-top: 90px;">
            <div style="font-size: 42px;">
                方案优势
            </div>
            <div class="txt18" style="width: 860px;margin-top: 60px;">
                辉腾光伏能源通过将信息技术、互联网技术与光伏技术跨界融合，集成高效光伏发电，高压储能，实现可视化家庭能源管理，场景化、简单化一键用电，为客户带来更安全、多发电、好管理的美好生活体验。
            </div>
            <div style="margin-top: 56px;">
                <img :src="farm_ys">
            </div>
        </section>

        <section class="body1200" style="margin-top: 150px;">
            <div style="font-size: 42px;">
                相关案例
            </div>
            <div class="htable"   style="margin-top: 60px; justify-content: space-between;">
                <div class="hcell"><div class="hec_al hec_al1"><div class="hec_albg1" ><div class="altxt1">
                    <div><img :src="address"></div>
                    <div>四川阿坝1MW扶贫光伏项目</div>
                </div> </div></div></div>
                <div class="hcell"><div class="hec_al hec_al2"><div class="hec_albg2" ><div class="altxt2">
                    <div><img :src="address"></div>
                    <div>四川理塘6MW扶贫光伏项目</div>
                </div> </div></div></div>
                <div class="hcell"><div class="hec_al hec_al3"> <div class="hec_albg3" ><div class="altxt3">
                    <div><img :src="address"></div>
                    <div>重庆巫溪整县扶贫光伏项目</div>
                </div> </div></div></div>
            </div>
        </section>

        <section style="height: 90px;"></section>
    </div>
</template>

<script>
    import myhead  from  '../components/myhead.vue'
    import cpblock_two  from  '../components/cpblock_two.vue'
    import cpblock_one  from  '../components/cpblock_one.vue'
    const param = {
        pageindex: 2,
        rows: 6,
        page: 1,
    }
    export default {
        name: "gflamp" ,
        components: {
            myhead,
            cpblock_two,
            cpblock_one
        },
        data() {
            return {
                param,
                farmgh:1,
                farm_gs1: require('../assets/farm_gs1.jpg'),
                farm_gs2: require('../assets/farm_gs2.jpg'),
                farm_gs3: require('../assets/farm_gs3.jpg'),
                farm_gs4: require('../assets/farm_gs4.jpg'),
                farm_gs5: require('../assets/farm_gs5.jpg'),


                farm_gg4: require('../assets/farm_gg4.png'),
                farm_gg5: require('../assets/farm_gg5.png'),

                farm_hj4: require('../assets/farm_hj4.png'),
                farm_hj5: require('../assets/farm_hj5.png'),
                farm_hj6: require('../assets/farm_hj6.png'),
                farm_hj7: require('../assets/farm_hj7.png'),


                farm_gk4: require('../assets/farm_gk4.png'),
                farm_gk5: require('../assets/farm_gk5.png'),
                farm_gk6: require('../assets/farm_gk6.png'),


                farm_sc4: require('../assets/farm_sc4.png'),
                farm_sc5: require('../assets/farm_sc5.png'),

                farm_ys: require('../assets/farm_ys.jpg'),
                address: require('../assets/address.png'),
            }
        },
        mounted() {

        },
        methods: {

        },
    }
</script>

<style scoped>
    .farmtemplate{
        color: #202020;
        text-align: left;

    }
    .ghm{
        width: 152px;
        text-align: center;
    }
    .txtH16{
        font-size: 16px;
        color:rgba(20,20,20,0.8);
        line-height: 80px;
    }
    .txt18{
        font-size: 18px;
        color:rgba(20,20,20,0.8);
        line-height: 32px;
    }
    .txt42{
        font-size: 42px;
        color:rgba(20,20,20,1);
        line-height: 42px;
    }
    .txt60{
        font-size: 60px;
        color:rgba(20,20,20,1);
        line-height: 60px;
    }
    .bannerfarm{
        background-image: url('~assets/farm_banner.jpg');
        height: 600px;
    }


    .farm_jg{
        background-image: url('~assets/farm_jg1.png');
        height: 678px;
    }

    .hec_al{
        width: 374px;
        height: 270px;
        border-radius: 10px;

    }

    .hec_al1{
        background-image: url('~assets/hec_al1.jpg');
    }
    .hec_al2{
        background-image: url('~assets/hec_al2.jpg');
    }
    .hec_al3{
        background-image: url('~assets/hec_al3.jpg');
    }
    .farm_ghblock{
        margin-top: 60px;
        /*height: 940px;*/
         /*border: 1px solid red;*/
    }
    .farm_gh{
        width: 374px;
        height: 270px;
        border-radius: 10px;
    }
    .farm_gh11{
        background-image: url('~assets/farm_gg1.jpg');
    }
    .farm_gh12{
        background-image: url('~assets/farm_gg2.jpg');
    }
    .farm_gh13{
        background-image: url('~assets/farm_gg3.jpg');
    }

    .farm_gh21{
        background-image: url('~assets/farm_hj1.jpg');
    }
    .farm_gh22{
        background-image: url('~assets/farm_hj2.jpg');
    }
    .farm_gh23{
        background-image: url('~assets/farm_hj3.jpg');
    }

    .farm_gh31{
        background-image: url('~assets/farm_gk1.jpg');
    }
    .farm_gh32{
        background-image: url('~assets/farm_gk2.jpg');
    }
    .farm_gh33{
        background-image: url('~assets/farm_gk3.jpg');
    }
    .farm_gh41{
        background-image: url('~assets/farm_sc1.jpg');
    }
    .farm_gh42{
        background-image: url('~assets/farm_sc2.jpg');
    }
    .farm_gh43{
        background-image: url('~assets/farm_sc3.jpg');
    }

    .farn_tdd{
        width: 320px;

        /*border: 1px solid red;*/
    }
    .farn_tddh{
        font-size: 18px;
        font-weight: bold;
        color: rgba(20,20,20,0.9);
        margin-top: 30px;
    }
    .farn_tddt{
        font-size: 16px;
        color: rgba(20,20,20,0.8);
        margin-top: 20px;
        line-height: 20px;
    }
    .altxt1{
        color: #ffffff;
        font-size: 18px;
        text-align: center;
        display: none;
    }
    .hec_albg1{
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items:center;
        display: flex;
    }

    .altxt2{
        color: #ffffff;
        font-size: 18px;
        text-align: center;
        display: none;
    }
    .hec_albg2{
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items:center;
        display: flex;
    }

    .altxt3{
        color: #ffffff;
        font-size: 18px;
        text-align: center;
        display: none;
    }
    .hec_albg3{
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items:center;
        display: flex;
    }
    .hec_al1:hover  .hec_albg1 {
        background-color: rgba(255,255,255,0.4)
    }
    .hec_al1:hover .altxt1{
        display: inline;
    }

    .hec_al2:hover  .hec_albg2 {
        background-color: rgba(255,255,255,0.4)
    }
    .hec_al2:hover .altxt2{
        display: inline;
    }
    .hec_al3:hover  .hec_albg3 {
        background-color: rgba(255,255,255,0.4)
    }
    .hec_al3:hover .altxt3{
        display: inline;
    }

    .btLine{
        border-bottom:2px solid #d7000f;
        padding-bottom: 28px;
    }
    .ghblockclick{
        cursor:pointer;
    }

    .ghblockclick:hover{
        color: #d7000f;
    }
</style>
